import { AlignJustify, ChevronsRight, DraftingCompass, Package, Phone, Sparkles } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import WhiteLogo from "@/assets/Cyan-black-logo.png";
import Dependable from "@/assets/dependable-hvac-installation-services.png";
import DesktopDashboard from "@/assets/Dashboard-Desktop.png";

export const DefaultPageMain = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-gray-vligth/60">
        <header className="sticky top-0 h-20 shadow-xl z-30 bg-White/90 background-blur-sm">
          <div className="container mx-auto flex justify-between h-full items-center">
            <img src={WhiteLogo} alt="Logo" className="h-8 md:h-10 object-fill mb-2" />
            <nav className="flex flex-row items-center">
              <ul className="fixed flex gap-4 w-full relative p-0 top-0 lg:border-none h-full transition-all duration-300 bg-White overflow-hidden">
                <Button
                  variant="outline"
                  className="mr-4 xl:mr-0 xl:bg-cyan-vivid xl:border-cyan-vivid xl:flex xl:px-8 tracking-wide hover:bg-cyan-vivid"
                  onClick={() => navigate("/auth/signin")}
                >
                  Sign in
                </Button>
                <Button
                  variant="outline"
                  className="hidden xl:bg-transparent xl:border-cyan-vivid xl:flex xl:px-8 xl:tracking-wide hover:bg-cyan-vivid"
                  onClick={() => navigate("/auth/signup")}
                >
                  Sign up
                </Button>
              </ul>
              <div className="cursor-pointer xl:hidden">
                <AlignJustify className="bg-muted/80 " />
              </div>
            </nav>
          </div>
        </header>
        <main className="flex flex-col mx-auto bg-transparent overflow-hidden">
          <section className="relative grid grid-cols-1 md:grid-cols-2 h-[440px] xl:h-[640px] bg-roof bg-bottom bg-no-repeat bg-fixed relative z-20 lg:bg-cover">
            <div className="grid mx-auto xl:px-8 bg-very-dark-blue/80 order-2 md:order-none items-center justify-center xl:justify-around">
              <div className="max-w-2xl items-center text-center xl:text-left lg:items-start">
                <h1 className="mb-8 font-inter font-semibold text-White/80">
                  All you need to prevent breakdowns in your HVAC system
                </h1>
                <p className="mb-8 text-White">
                  View relevant statistics in real time. Get notifications, alarms, average consumption and direct
                  contact with maintenance.
                </p>
                <Button variant="outline" className="bg-cyan-vivid mx-auto xl:mx-0 border-Black/10 ">
                  Watch Demo
                </Button>
              </div>
            </div>
            <div className="grid items-center justify-center order-1 md:order-none"></div>
          </section>
          <section className="relative mt-20 xl:mt-48 z-20">
            <div className="container mx-auto max-w-screen-xl">
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
                <div className="grid grid-col p-6 w-full text-center bg-strong-cyan rounded-lg">
                  <DraftingCompass className="w-full text-blue-brand text-center justify-center" />
                  <h3 className="text-2xl font-semibold my-5">Project Planning</h3>
                  <p>There are many variations of the passages of lorem Ipsum from available, majority.</p>
                  <div className="flex flex-row justify-center items-center">
                    <a
                      href="#"
                      className="my-5 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                    >
                      Read more
                    </a>
                    <ChevronsRight className="ml-1 mt-1 h-4 w-4 items-end" />
                  </div>
                </div>
                <div className="grid grid-col p-6 w-full text-center bg-strong-cyan rounded-md ">
                  <Sparkles className="w-full text-blue-brand text-center justify-center" />
                  <h3 className="my-5 text-2xl font-semibold">Gaining Materials</h3>
                  <p>There are many variations of the passages of lorem Ipsum from available, majority.</p>
                  <div className="flex flex-row justify-center items-center">
                    <a
                      href="#"
                      className="my-5 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                    >
                      Read more
                    </a>
                    <ChevronsRight className="ml-1 mt-1 h-4 w-4 items-end" />
                  </div>
                </div>
                <div className="grid grid-col p-6 w-full text-center bg-strong-cyan rounded-md">
                  <Package className="w-full text-blue-brand text-center justify-center" />
                  <h3 className="my-5 text-2xl font-semibold">Project Execution</h3>
                  <p>There are many variations of the passages of lorem Ipsum from available, majority.</p>
                  <div className="flex flex-row justify-center items-center">
                    <a
                      href="#"
                      className="my-5 gap-2 font-medium leading-none hover:pointer hover:accent-Black hover:underline"
                    >
                      Read more
                    </a>
                    <ChevronsRight className="ml-1 mt-1 h-4 w-4 items-end" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative mt-20 xl:mt-48 z-20 bg-gray-200">
            <div className="container mx-auto xl:px-0 ">
              <div className="flex justify-center">
                <div className="flex flex-col max-w-screen-xl items-center justify-between text-center gap-8 xl:flex-row xl:text-left">
                  <div className="flex flex-col p-12 items-center flex-1 order-2 xl:order-none xl:items-start gap-8">
                    <h2 className="font-semibold text-2xl xl:text-4xl">
                      Stay informed in real time about the health of your HVAC!
                    </h2>
                    <p className="tracking-wide">
                      The values ​​emitted by your HVAC are compared in real time to its healthy operating range. In
                      case of failures or unexpected values ​​in the records, maintenance suggestions and alarms are
                      displayed, easily configurable in your preferred browser or on your mobile via SMS.
                    </p>

                    <div className="grid grid-rows-2 grid-flow-col gap-1">
                      <div className="row-span-3 bg-yellow-pale rounded-full p-4">
                        <Phone className="h-5 w-5" />
                      </div>
                      <div className="cols-span-2 text-xl font-bold">+1 305-764 0580</div>
                      <h3 className="row-span-2 col-span-2">Call Us Anytime</h3>
                    </div>
                    <div>
                      <Button variant="outline" className="bg-cyan-vivid mx-auto xl:mx-0 border-Black/10 ">
                        Request information
                      </Button>
                    </div>
                  </div>
                  <div className="flex order-1 xl:order-none max-w-screen-md">
                    <div className="rounded-full overflow-visible">
                      <img src={Dependable} alt="Picture" className="rounded-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="relative mt-20 xl:mt-48 z-20">
            <div className="container mx-auto px-6 rounded-xl">
              <div className="grid grid-col w-full justify-center gap-4">
                <h2 className="font-semibold text-2xl xl:text-4xl justify-self-center">
                  We efficiently monitor your HVAC!
                </h2>
                <div className="grid grid-cols-1 xl:grid-cols-2">
                  <div className="p-8">
                    <img src={DesktopDashboard} alt="DesktopMobile" className="rounded-2xl" />
                  </div>
                  <div className="flex flex-col p-12">
                    <h2 className="font-semibold font-medium text-2xl text-slate-400 pb-2">HVAC</h2>
                    <h2 className="font-semibold font-medium text-2xl pb-8">HVAC</h2>
                    <p className="text-lg text-start">
                      We offer you the details of the recorded results as well as a summary page of all the monitored
                      equipment. You can obtain an approximate calculation of energy consumption in the selected period
                      and estimate costs according to average consumption behavior.
                    </p>
                    <div className="pt-8">
                      <Button variant="outline" className="bg-cyan-vivid  border-Black/10 ">
                        Get started
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

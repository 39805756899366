import React from "react";
import { Outlet } from "react-router-dom";
import { TopBarAuth } from "@/features/dashboard/header/TopBarAuth.tsx";

const AuthPage = () => {
  return (
    <>
      <div className="relative flex min-h-screen flex-col bg-background">
        <div className="fixed top-0 left-0 right-0 z-30 shadow-md">
          <TopBarAuth />
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default AuthPage;

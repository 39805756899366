import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DefaultPageMain } from "@/features/landing/main";
import { PrivateRoute } from "@/PrivateRoute";
import { PartnerPage } from "@/features/partner/PartnerPage";
import AuthPage from "@/features/auth/AuthPage";
import SignIn from "@/features/auth/signin/SignIn";
import SignUp from "@/features/auth/signup/SignUp";
import AuthAction from "@/features/auth/action/AuthAction";
import { SetNewPasswordSuccess } from "@/features/auth/action/reset-password/SetNewPasswordSuccess";
import { PasswordResetRequest } from "@/features/auth/action/reset-password/PasswordResetRequest";
import { PasswordResetMailSent } from "@/features/auth/action/reset-password/PasswordResetMailSent";
import { VerifyEmailRequest } from "@/features/auth/action/verify-email/VerifyEmailRequest";
import { UpdateEmail } from "@/features/auth/action/update-email/UpdateEmail";
import CompleteSignUp from "@/features/auth/action/complete-signup/CompleteSignUp";
import UnsubscribePage from "@/features/auth/unsubscribe/unsubscribePage";
import { DashboardPage } from "@/features/dashboard/DashboardPage";
import SignInWithMailPage from "@/features/auth/signin-with-email/SignInWithMailPage";
import { EmailLinkSentPage } from "@/features/auth/signin-with-email/EmailLinkSentPage";
import { NewRegistration } from "./features/partner/new-registration/NewRegistration";
import { PaymentMethodPage } from "./features/payment/PaymentMethodsPage";

export const RoutesGuardian = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultPageMain />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/partner"
          element={
            <PrivateRoute>
              <PartnerPage />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/partner/new-registration"
          element={
            <PrivateRoute>
              <NewRegistration />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/payment-methods"
          element={
            <PrivateRoute>
              <PaymentMethodPage />
            </PrivateRoute>
          }
        ></Route>
        <Route path="/auth" element={<AuthPage />}>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="action" element={<AuthAction />} />
          <Route path="set-new-password-success" element={<SetNewPasswordSuccess />} />
          <Route path="password-reset" element={<PasswordResetRequest />} />
          <Route path="password-reset-mail-sent" element={<PasswordResetMailSent />} />
          <Route path="verify-mail-request" element={<VerifyEmailRequest />} />
          <Route path="update-mail" element={<UpdateEmail />} />
          <Route path="complete-signup" element={<CompleteSignUp />} />
          <Route path="signin-link" element={<SignInWithMailPage />} />
          <Route path="signin-link-sent" element={<EmailLinkSentPage />} />
          <Route path="unsubscribe" element={<UnsubscribePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

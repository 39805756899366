import React from "react";
import { Button } from "@/components/ui/button";
import { CalendarDateRangePicker } from "@/features/dashboard/components/data-range-picker.tsx";

type Props = {};

const Reports = (props: Props) => {
  return (
    <>
      <div className="flex items-center space-x-2">
        <CalendarDateRangePicker />
        <Button>Download</Button>
      </div>
    </>
  );
};

export default Reports;

import * as R from "ramda";
import { Subject } from "rxjs";
import { Blob } from "@rtbot-dev/ui-proto-gen";

const remapKey = (k: string) => {
  switch (k) {
    case "t1":
      return "temperatureLow";
    case "t2":
      return "temperatureHigh";
    case "p1":
      return "pressureLow";
    case "p2":
      return "pressureHigh";
    case "i1":
      return "current1";
    case "i2":
      return "current2";
    case "i3":
      return "current3";
    default:
      return k;
  }
};

type Row = { time: number; value: number };

export const getStreamFromWS: (kernelId: string, subpath: string) => Subject<Record<string, Row[]>> = (
  kernelId: string,
  subpath: string
) => {
  const subject = new Subject<Record<string, Row[]>>();

  const endpoint = `wss://api.cool-guardian.com/outbound-websocket/${kernelId}/${subpath}`;
  //const endpoint = `ws://localhost:3409/outbound-websocket/${kernelId}/${subpath}`;

  const ws = new WebSocket(endpoint);
  ws.binaryType = "arraybuffer";

  ws.onopen = () => {
    console.log("Connection opened");
  };

  ws.onmessage = async (event) => {
    const arrayBuffer = event.data;
    const data = new Uint8Array(arrayBuffer);
    const blob = Blob.fromBinary(data);
    const mappedData = Object.entries(blob.payload).reduce((acc, [key, { messages }]) => {
      const remappedKey = remapKey(key);
      const row = messages.map((v) => ({ time: 1000 * Number(v.time.seconds), value: v.value }));
      return { ...acc, [remappedKey]: row };
    }, {});
    console.log("Data received ", blob, " > ", mappedData);
    subject.next(mappedData);
  };

  ws.onerror = (error) => {
    console.error("Error", error);
    subject.error(error);
  };

  ws.onclose = () => {
    console.log("Connection closed");
    subject.complete();
  };

  return subject;
};

import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface PaymentOptionsCardProps {
  subscribed: boolean;
}

const PaymentOptionsCard: React.FC<PaymentOptionsCardProps> = ({ subscribed }) => {
  const navigate = useNavigate();
  if (subscribed) {
    return null;
  }

  return (
    <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4">
        <h2 className="text-xl font-bold mb-2">Opciones de Pago</h2>
        <p className="text-gray-700 text-base">
          Choose one of the following options to continue enjoying our services.
        </p>
      </div>
      <div className="px-6 pt-4 pb-2">
        <Button
          variant="default"
          onClick={() => {
            navigate("/payment-methods");
          }}
        >
          Pay now
        </Button>
      </div>
    </div>
  );
};

export default PaymentOptionsCard;

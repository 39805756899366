import { create, StoreApi, UseBoundStore } from "zustand";
import { DeviceData, httpApi } from "@/http-api.ts";

export type DashboardStoreState = {
  loadingDevices: boolean;
  loadingHistoricalData: boolean;
  showMetric: boolean | string;
  toggleShowMetric: () => void;
  status: string | undefined;
  devices: DeviceData[];
};

export type DashboardStoreActions = {
  loadDevices: () => Promise<void>;
  setHistoricalDataLoading: (loading: boolean) => void;
};

export type DashboardStore = DashboardStoreState & DashboardStoreActions;

export const useDashboardStore: UseBoundStore<StoreApi<DashboardStore>> = create<DashboardStore>((set, get) => ({
  loadingDevices: true,
  loadingHistoricalData: true,
  showMetric: false,
  status: undefined,
  toggleShowMetric: () => set((state) => ({ showMetric: !state.showMetric })),
  devices: [],
  setHistoricalDataLoading: (loading) => set({ loadingHistoricalData: loading }),
  async loadDevices() {
    const { data: devices } = await httpApi.getDevices();
    console.log("Devices", devices);
    // TODO: read the list of devices from the API
    set(() => ({
      loadingDevices: false,
      devices,
    }));
  },
}));

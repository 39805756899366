import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../auth-store";
import IconGoogle from "../../../assets/icons8-google.svg";
import { useToast } from "@/components/ui/use-toast.ts";
import { ToastAction } from "@/components/ui/toast.tsx";
import { cn } from "@/components/utils.ts";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Icons } from "@/components/ui/icons";
import { Send } from "lucide-react";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function SignInForm({ className, ...props }: UserAuthFormProps) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { signIn, signInWithGoogle, user, loading, error, clearError, persistenceAuth, setPersistenceAuth } =
    useAuthStore((state) => state);

  useEffect(() => {
    console.log("User", user);
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    console.log("Use Effect", error);
    if (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Account not found or incorrect password",
        className: "flex flex-inline py-3",
        action: (
          <ToastAction
            className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
            altText="Retry"
            onClick={() => {
              navigate("/auth/signup");
            }}
          >
            Sign up
          </ToastAction>
        ),
      });
    }
    clearError();
  }, [error]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
  }

  const handleSubmit = async (e: any) => {
    try {
      await signIn(email, password);
      console.log("Done");
    } catch (error) {
      console.log("Error", error);
    } finally {
      //  console.log("User", user);
    }
  };

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <p className="text-sm text-muted-foreground font-bold tracking-tight">Your Email</p>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              onChange={(event) => setEmail(event.target.value)}
              disabled={loading}
            />
            <p className="text-sm text-muted-foreground font-bold tracking-tight">Password</p>
            <Input
              id="password"
              placeholder="*********"
              type="password"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              onChange={(event) => setPassword(event.target.value)}
              disabled={loading}
            />
          </div>
          <Button disabled={loading} className="text-foreground" onClick={handleSubmit}>
            {loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
            Sign In
          </Button>
        </div>
      </form>
      <div>
        <div className="grid grid-cols-2 items-center">
          <div className="flex flex-row space-x-1 items-center">
            <Checkbox
              id="terms1"
              checked={persistenceAuth}
              onCheckedChange={() => setPersistenceAuth(!persistenceAuth)}
            />
            <label htmlFor="terms1" className="text-sm text-muted-foreground float-left">
              Remember me
            </label>
          </div>
          <label
            className="text-sm text-end underline text-muted-foreground peer-disabled:cursor-allowed peer-disabled:opacity-70 cursor-pointer"
            onClick={() => navigate("../password-reset")}
          >
            Forgot Password
          </label>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or sign in with</span>
        </div>
      </div>
      <Button
        onClick={() => signInWithGoogle()}
        variant="outline"
        type="button"
        disabled={loading}
        className="text-foreground"
      >
        {loading ? (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <img src={IconGoogle} alt="Google Icon" className="mr-2 h-4 w-4" />
        )}{" "}
        Google
      </Button>
      <Button
        onClick={() => {
          navigate("../signin-link");
        }}
        variant="outline"
        type="button"
        disabled={loading}
        className="text-foreground"
      >
        {loading ? <Icons.spinner className="mr-2 h-4 w-4 animate-spin" /> : <Send className="h-4 w-4 m-2" />}
        Sign in with Email
      </Button>
    </div>
  );
}

import { TopBarAuth } from "@/features/dashboard/header/TopBarAuth.tsx";
import CustomerTable from "@/features/partner/customer/customerTable.tsx";
import { useState } from "react";
import PaymentOptionsCard from "../payment/PaymentOptionsCard";
import { Button } from "@/components/ui/button";

export const PartnerPage = () => {
  //Get from the DeviceData API if you are subscribed or not
  const [subscribed, setSubscribed] = useState(true);

  const handleUnsubscribe = () => {
    setSubscribed(false);
  };

  return (
    <>
      <div className="min-h-screen block bg-background">
        <TopBarAuth />
        <div className="p-8">
          <main className="flex flex-row w-full justify-between">
            <div className="">
              <h1 className="text-2xl font-medium pb-8">Registered devices</h1>
              <div className="">
                <CustomerTable />
              </div>
            </div>
            <div className="">TODO: AddDevice</div>
            <Button
              onClick={handleUnsubscribe}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Cancelar Suscripción
            </Button>
            <PaymentOptionsCard subscribed={subscribed} />
          </main>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { concatMap, delay, merge, mergeAll, of, Subject } from "rxjs";
import { ResponsiveContainer } from "recharts";
import { Checkbox } from "@/components/ui/checkbox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format, formatDistance } from "date-fns";
import { enUS } from "date-fns/locale";
import { getPsiToF, Refrigerant } from "@/utils/refrigerants.ts";
import { useAuthStore } from "@/features/auth/auth-store.ts";
import { useDashboardStore } from "@/features/dashboard/dashboard-store.ts";
import { getStreamFromWS } from "@/features/dashboard/echart/getStreamFromWS.ts";
import { TopBar } from "@/features/dashboard/header/TopBar.tsx";
import WelcomeUser from "@/features/dashboard/welcome-user/welcomeUser.tsx";
import { DigitalValues } from "@/features/dashboard/echart/digitalValues.tsx";
import { SuperHeat } from "@/features/dashboard/gauges/SuperHeat.tsx";
import { SubCooling } from "@/features/dashboard/gauges/SubCooling.tsx";
import { EChartsStreamVisualizer } from "@/features/dashboard/echart/EChartsStreamVisualizer.tsx";
import Reports from "@/features/dashboard/reports/Reports.tsx";
import { OverallStatus } from "@/features/dashboard/status/OverallStatus.tsx";
import { DeviceData } from "@/http-api";

type SensorList = {
  data$: Subject<{
    [sensorId: string]: { time: number; value: number }[];
  }>;
  // options to customize the chart
  options?: any;
  sensorType?: "lowLine" | "highLine" | "current";
  measure?: { dual: boolean; imperial: string; metric: string };
};

export const DashboardPage = () => {
  const clear$ = new Subject<void>();
  const show$ = new Subject<boolean>();
  const { isNewUser } = useAuthStore((state) => state);
  const [searchParams] = useSearchParams();

  const [lowLine$, setLowLine$] = useState<Subject<Record<string, { time: number; value: number }[]>> | null>(null);
  const [highLine$, setHighLine$] = useState<Subject<Record<string, { time: number; value: number }[]>> | null>(null);
  const [currents$, setCurrents$] = useState<Subject<Record<string, { time: number; value: number }[]>> | null>(null);
  const [all$, setAll$] = useState<Subject<Record<string, { time: number; value: number }[]>> | null>(null);
  const [device, setDevice] = useState<DeviceData | null>(null);
  const [lastSensorUpdate, setLastSensorUpdate] = useState<{ [sensorType: string]: Date }>({});

  const {
    status,
    loadDevices,
    devices,
    loadingHistoricalData,
    setHistoricalDataLoading,
    showMetric,
    toggleShowMetric,
  } = useDashboardStore((state) => state);

  useEffect(() => {
    // load devices on mount
    loadDevices()
      .then(() => {
        console.log("Devices loaded");
      })
      .catch((error) => {
        console.error("Error loading devices", error);
      });
  }, []);

  useEffect(() => {
    if (!device) return;
    const kernelId = searchParams.get("kernelId") ?? device.kernelIds[0];
    console.log("Selected deviceId", device.deviceId, "kernelId", kernelId);
    const lowLine$ = getStreamFromWS(kernelId, "low-line");
    const highLine$ = getStreamFromWS(kernelId, "high-line");
    const currents$ = getStreamFromWS(kernelId, "currents");
    // combine all the streams into one, that emits whatever comes first
    const all$ = new Subject();
    merge(lowLine$, highLine$, currents$).subscribe(all$);
    setLowLine$(lowLine$);
    setHighLine$(highLine$);
    setCurrents$(currents$);
    // @ts-ignore
    setAll$(all$);
    show$.next(false);
    lowLine$.subscribe((data) => {
      if (loadingHistoricalData) {
        setHistoricalDataLoading(false);
        show$.next(true);
      }
      // get the timestamp of the last update for each sensor
      const lastUpdateTemperatureLow = data.temperatureLow[data.temperatureLow.length - 1].time;
      setLastSensorUpdate({
        pressure: new Date(lastUpdateTemperatureLow),
        temperature: new Date(lastUpdateTemperatureLow),
        current: new Date(lastUpdateTemperatureLow),
      });
    });
  }, [device]);

  useEffect(() => {
    // get the kernel id from the device list
    if (devices.length === 0) return;

    const deviceId = searchParams.get("deviceId") ?? devices[0].deviceId;
    setDevice(devices.find((device) => device.deviceId === deviceId));
  }, [devices]);

  // TODO: this should come from the backend eventually
  const sensorList: SensorList[] = [
    {
      sensorType: "lowLine",
      measure: { dual: true, imperial: "PSI", metric: "kP" },
      data$: lowLine$,
      options: {
        series: [
          {
            title: "Low Line",
            radius: "90%",
            type: "gauge",
            center: ["50%", "60%"],
            startAngle: 220,
            endAngle: -40,
            min: 0,
            max: 300,
            z: 200,
            splitNumber: 10,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "pressureLow",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "kP" : "PSI",
                progress: {
                  show: true,
                  width: 20,
                },
                detail: {
                  valueAnimation: true,
                },
                pointer: {
                  show: true,
                },
                itemStyle: {
                  color: "hsl(191.5,100%,51.8%)",
                  shadowColor: "#FD7349",
                },
              },
              {
                sensorId: "temperatureLow",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "℃" : "℉",
                pointer: {
                  show: false,
                },
                detail: {
                  valueAnimation: true,
                },
                itemStyle: {
                  color: "hsl(191.9,100%,84.1%)",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
    {
      sensorType: "highLine",
      measure: { dual: true, imperial: "℉", metric: "℃" },
      data$: highLine$,
      options: {
        series: [
          {
            title: "High Line",
            min: 0,
            max: 450,
            radius: "90%",
            center: ["50%", "60%"],
            startAngle: 220,
            endAngle: -40,
            type: "gauge",
            splitNumber: 10,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "pressureHigh",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "kP" : "PSI",
                progress: {
                  show: true,
                  width: 20,
                },
                detail: {
                  valueAnimation: true,
                },
                pointer: {
                  show: true,
                },
                itemStyle: {
                  color: "hsl(14.2,100%,78.4%)",
                  shadowColor: "#FD7349",
                },
              },
              {
                sensorId: "temperatureHigh",
                value: 0,
                // this should match one of the keys in the object emitted by the data$ observable
                unit: showMetric ? "℃" : "℉",
                detail: {
                  valueAnimation: true,
                },
                progress: {
                  show: true,
                  width: 8,
                },
                itemStyle: {
                  color: "hsl(14.5,97.8%,63.5%)",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
    {
      sensorType: "current",
      measure: { dual: false, imperial: "A", metric: "A" },
      data$: currents$,
      options: {
        series: [
          {
            title: "Current",
            startAngle: 220,
            endAngle: -40,
            radius: "90%",
            center: ["50%", "60%"],
            type: "gauge",
            splitNumber: 5,
            min: 0,
            max: 50,
            progress: {
              show: true,
              overlap: false,
              roundCap: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 35,
              },
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            splitLine: {
              distance: -52,
              length: 8,
              lineStyle: {
                width: 2,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 7,
              color: "#999",
              fontSize: 10,
            },
            detail: {
              show: false,
            },
            data: [
              {
                sensorId: "current1",
                name: "Compressor",
                unit: "A",
                value: 0,
                itemStyle: {
                  color: "hsl(191.4,100%,49.4%)",
                  shadowColor: "#FD7349",
                },
                // this should match one of the keys in the object emitted by the data$ observable
              },
              {
                sensorId: "current2",

                value: 0,
                name: "Fan",
                // this should match one of the keys in the object emitted by the data$ observable
                unit: "A",
                itemStyle: {
                  color: "#0096ba",
                  shadowColor: "#FD7349",
                },
              },
            ],
          },
        ],
      },
    },
  ];

  return (
    <div className="relative flex min-h-screen flex-col">
      <div className="fixed top-0 w-full z-30 shadow-md">
        <TopBar />
      </div>
      <div className="h-screen flex justify-center">
        <div className="container max-w-screen-2xl items-center bg-transparent dark:bg-transparent">
          <div className="flex-1 space-y-4 w-full mt-16 xl:p-6 rounded-lg">
            <div className="flex-col w-full items-center sm:space-y-8 content-start">
              <h3 className="text-3xl text-foreground tracking-tighter font-inter heading-tight text-start md:text-4xl lg:leading-[1.1] mb-4 dark:text-blue-brand">
                Devices
              </h3>
              {isNewUser && <WelcomeUser />}
              {status && <OverallStatus status={status} />}
            </div>
            <div className="mt-16">
              <Tabs defaultValue={device?.deviceId} value={device?.deviceId} className="mt-4 sm:mt-8 space-y-4">
                <TabsList>
                  {devices.map(({ deviceId }) => {
                    return (
                      <TabsTrigger
                        key={deviceId}
                        value={deviceId}
                        onClick={() => {
                          console.log("Showing deviceId", deviceId);
                          setDevice(devices.find((device) => device.deviceId === deviceId));
                        }}
                      >
                        {
                          // TODO: allow devices to have name
                          deviceId
                        }
                      </TabsTrigger>
                    );
                  })}
                </TabsList>
                {devices.map(({ deviceId, monitoring, subscribed }) => {
                  return (
                    <TabsContent key={deviceId} value={deviceId} className="space-y-4">
                      <main className="grid flex-1 grid-cols-1 sm:py-0">
                        <div className="md:grid md:grid-cols-2 2xl:grid-cols-12 gap-8 space-y-4 sm:space-y-0">
                          {lowLine$ &&
                            highLine$ &&
                            currents$ &&
                            sensorList.map(({ sensorType, options, data$ }, index) => {
                              return (
                                <Card
                                  key={index}
                                  className={`py-4 sm:p-4 relative shadow-sm md:col-span-1 2xl:col-span-4`}
                                >
                                  <CardHeader className="p-0">
                                    <CardTitle className="flex pb-2 sm:pb-6 justify-center font-semibold leading-none tracking-tight">
                                      {options.series.map((option, i) => {
                                        return (
                                          <strong
                                            key={i}
                                            className="flex font-semibold leading-none sm:text-lg md:text-2xl tracking-tight text-foreground dark:text-blue-brand justify-end"
                                          >
                                            {option.title}
                                          </strong>
                                        );
                                      })}
                                    </CardTitle>
                                  </CardHeader>
                                  <CardContent className="sm:items-center p-0 bg-transparent">
                                    <div className="flex w-full justify-center">
                                      <div className="w-full absolute sm:w-1/2 z-100 pt-20 ">
                                        {sensorType.includes("current") ? (
                                          <div className="pt-2"></div>
                                        ) : (
                                          <span className="flex justify-center text-xs sm:text-sm w-full text-foreground text-center dark:text-blue-brand">
                                            {monitoring?.refrigerant ?? "unknown"}
                                          </span>
                                        )}
                                        {
                                          // we only support one series for now
                                          options.series[0].data.map((data, index) => (
                                            <>
                                              <span className="flex justify-center text-sm w-full text-foreground text-center dark:text-blue-brand pt-2">
                                                {data.name}
                                              </span>
                                              <DigitalValues key={index} data={data} data$={data$} />
                                            </>
                                          ))
                                        }
                                        {sensorType === "lowLine" && (
                                          <SuperHeat
                                            data$={data$}
                                            temperatureSensorId="temperatureLow"
                                            pressureSensorId="pressureLow"
                                            showMetric={showMetric}
                                          />
                                        )}
                                        {sensorType === "highLine" && (
                                          <SubCooling
                                            data$={data$}
                                            temperatureSensorId="temperatureHigh"
                                            pressureSensorId="pressureHigh"
                                            showMetric={showMetric}
                                          />
                                        )}
                                      </div>
                                      <ResponsiveContainer width="100%" height="100%" className={"flex items-stretch"}>
                                        <EChartsStreamVisualizer
                                          options={options}
                                          data$={data$}
                                          clear$={clear$}
                                          minHeight="250px"
                                          minWidth="250px"
                                        />
                                      </ResponsiveContainer>
                                    </div>
                                  </CardContent>
                                  <CardDescription className="flex text-xs sm:text-sm justify-center pt-10 text-slate-400">
                                    {`Last update: ${
                                      lastSensorUpdate[sensorType]
                                        ? formatDistance(lastSensorUpdate[sensorType], new Date())
                                        : "-"
                                    }`}
                                  </CardDescription>
                                </Card>
                              );
                            })}
                        </div>

                        <section className="flex flex-row space-y-1.5 p-3 text-sm text-muted-foreground justify-between">
                          <div className="flex flex-col text-left ">
                            <h3>{`Device: ${deviceId}`}</h3>
                            <h3>Subscription: {subscribed ? "Active" : "Inactive"}</h3>
                            {/*
                            TODO: add subscription information on the server side
                            
                            device.subscription?.lastPayment && (
                              <h3>
                                {`Last Payment: ${format(
                                  device.subscription.lastPayment,
                                  "MM/dd/yyyy"
                                )}, ${formatDistance(device.subscription.lastPayment, new Date(), {
                                  locale: enUS,
                                  addSuffix: true,
                                })}`}
                              </h3>
                            )*/}
                            <h3>Refrigerant: {monitoring?.refrigerant ?? "unknown"}</h3>
                          </div>
                          <div className="flex top-0 items-start">
                            <div className="flex flex-row items-center space-x-1">
                              <Checkbox id="terms1" onCheckedChange={(_) => toggleShowMetric()} />
                              <div className="leading-none">
                                <label
                                  htmlFor="terms1"
                                  className="flex items-center text-xs font-light text-muted-foreground leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                  SI
                                </label>
                              </div>
                            </div>
                          </div>
                        </section>

                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle className="border-0 shadow-none">Recent Historical Data</CardTitle>
                          </CardHeader>
                          <CardContent>
                            <ResponsiveContainer width="100%" height="100%" className={"flex items-stretch"}>
                              <EChartsStreamVisualizer
                                options={{
                                  name: "Pressure",
                                  animation: false,
                                  tooltip: {
                                    trigger: "axis",
                                    axisPointer: {
                                      type: "cross",
                                    },
                                    formatter: (params) => {
                                      let html = `${new Date(params[0].value[0]).toLocaleString()}<br/>`;
                                      html += "<br />";
                                      const temperatureHigh = params.find((p) => p.seriesName === "temperatureHigh");
                                      const temperatureLow = params.find((p) => p.seriesName === "temperatureLow");
                                      const pressureHigh = params.find((p) => p.seriesName === "pressureHigh");
                                      const pressureLow = params.find((p) => p.seriesName === "pressureLow");
                                      const current1 = params.find((p) => p.seriesName === "current1");
                                      const current2 = params.find((p) => p.seriesName === "current2");
                                      const current3 = params.find((p) => p.seriesName === "current3");

                                      if (temperatureLow && pressureLow) {
                                        html += "<b>Low Line</b> <br>";
                                        html += `<div style="background-color: ${
                                          pressureLow.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Pressure: <b>${Math.round(
                                          pressureLow.value[1]
                                        )} </b>${showMetric ? "kP" : "PSI"}<br/>`;

                                        html += `<div style="background-color: ${
                                          temperatureLow.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Temperature: <b>${Math.round(
                                          temperatureLow.value[1]
                                        )} </b>${showMetric ? "℃" : "℉"}<br/>`;

                                        if (device?.monitoring.refrigerant) {
                                          const vsat = getPsiToF(device?.monitoring?.refrigerant as Refrigerant)(
                                            pressureLow.value[1]
                                          );
                                          html += `Vapor Saturation: <b>${Math.round(vsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                          html += `Superheat: <b>${Math.round(temperatureLow.value[1] - vsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                        }

                                        html += `<br/>`;
                                      }

                                      if (temperatureHigh && pressureHigh) {
                                        html += "<b>High Line</b> <br>";
                                        html += `<div style="background-color: ${
                                          pressureHigh.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Pressure: <b>${Math.round(
                                          pressureHigh.value[1]
                                        )} </b></b>${showMetric ? "kP" : "PSI"}<br/>`;

                                        html += `<div style="background-color: ${
                                          temperatureHigh.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Temperature: <b>${Math.round(
                                          temperatureHigh.value[1]
                                        )} </b>${showMetric ? "℃" : "℉"}<br/>`;

                                        if (device?.monitoring?.refrigerant) {
                                          const lsat = getPsiToF(device?.monitoring?.refrigerant as Refrigerant)(
                                            pressureHigh.value[1]
                                          );
                                          html += `Liquid Saturation: <b>${Math.round(lsat)} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                          html += `Subcooling: <b>${Math.round(lsat - temperatureHigh.value[1])} </b>${
                                            showMetric ? "℃" : "℉"
                                          }<br/>`;
                                        }
                                        html += `<br/>`;
                                      }

                                      if (current1) {
                                        html += "<b>Current</b> <br>";
                                        html += `<div style="background-color: ${
                                          current1.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 1: <b>${Math.round(
                                          current1.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      if (current2) {
                                        html += `<div style="background-color: ${
                                          current2.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 2: <b>${Math.round(
                                          current2.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      if (current3) {
                                        html += `<div style="background-color: ${
                                          current3.color
                                        }; height: 8px; width: 8px;  border-radius: 50%; display: inline-block;"> </div> Current 3: <b>${Math.round(
                                          current3.value[1]
                                        )} </b>A<br/>`;
                                      }

                                      return html;
                                    },
                                  },
                                  xAxis: [
                                    {
                                      min: "dataMin",
                                      max: "dataMax",
                                      type: "time",
                                      gridIndex: 0,
                                    },
                                    {
                                      min: "dataMin",
                                      max: "dataMax",
                                      type: "time",
                                      gridIndex: 1,
                                    },
                                  ],
                                  boundaryGap: true,
                                  grid: [
                                    {
                                      height: "35%",
                                    },
                                    {
                                      top: "55%",
                                      height: "30%",
                                    },
                                  ],
                                  yAxis: [
                                    {
                                      type: "value",
                                      name: "Pressure",
                                      position: "right",
                                      alignTicks: true,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} ${showMetric ? "kP" : "PSI"} `,
                                      },
                                      gridIndex: 0,
                                    },
                                    {
                                      type: "value",
                                      name: "Temperature",
                                      position: "left",
                                      alignTicks: true,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} ${showMetric ? "℃" : "℉"} `,
                                      },
                                      gridIndex: 0,
                                    },
                                    {
                                      type: "value",
                                      name: "Current",
                                      position: "right",
                                      alignTicks: true,
                                      gridIndex: 1,
                                      axisLabel: {
                                        formatter: (value) => `${Math.round(value)} A`,
                                      },
                                    },
                                  ],
                                  dataZoom: [
                                    {
                                      show: true,
                                      type: "slider",
                                      top: "90%",
                                      xAxisIndex: [0, 1],
                                    },
                                  ],
                                  series: [
                                    {
                                      name: "temperatureLow",
                                      color: "hsl(14.5,97.8%,63.5%)",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 1,
                                      type: "line",
                                    },
                                    {
                                      name: "temperatureHigh",
                                      color: "hsl(14.2,100%,78.4%)",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 1,
                                      type: "line",
                                    },
                                    {
                                      name: "pressureLow",
                                      color: "hsl(191.9,100%,84.1%)",
                                      data: [],
                                      type: "bar",
                                      xAxisIndex: 0,
                                      yAxisIndex: 0,
                                      backgroundStyle: {
                                        color: "rgba(180, 180, 180, 0.2)",
                                      },
                                    },
                                    {
                                      name: "pressureHigh",
                                      color: "hsl(191.5,100%,51.8%)",
                                      type: "bar",
                                      data: [],
                                      xAxisIndex: 0,
                                      yAxisIndex: 0,
                                    },
                                    {
                                      name: "current1",
                                      color: "hsl(191.4,100%,49.4%)",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                    {
                                      name: "current2",
                                      color: "#0096ba",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                    {
                                      name: "current3",
                                      color: "#002730",
                                      data: [],
                                      type: "line",
                                      xAxisIndex: 1,
                                      yAxisIndex: 2,
                                    },
                                  ],
                                }}
                                data$={all$}
                                clear$={clear$}
                                minHeight={"600px"}
                              />
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle>Events</CardTitle>
                            <CardDescription>You have 25 unread events this month.</CardDescription>
                          </CardHeader>
                          <CardContent></CardContent>
                        </Card>
                        <Card className="mt-4">
                          <CardHeader>
                            <CardTitle>Reports</CardTitle>
                            <CardDescription>Please select the period to analyze</CardDescription>
                          </CardHeader>
                          <CardContent>
                            <Reports />
                          </CardContent>
                        </Card>
                      </main>
                    </TabsContent>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Device, NewCustomerSetup } from "@rtbot-dev/json-schemas";

class HttpApi {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: "https://api.cool-guardian.com/v1",
    });
  }

  setToken(token: string) {
    // set the token in the Authorization header using interceptors
    this.client.interceptors.request.use((config: any) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });
  }

  private request(req: AxiosRequestConfig<any>) {
    return this.client.request(req);
  }

  getDevices() {
    return this.request({
      method: "GET",
      url: "/devices",
    }) as Promise<AxiosResponse<any>>; // TODO: define the type of the response
  }

  partnerStartSetup(newCustomerSetup: NewCustomerSetup) {
    return this.request({
      method: "POST",
      url: "/partner/start-setup",
      data: newCustomerSetup,
    });
  }

  partnerContinueSetupWithCode(code: string) {
    return this.request({
      method: "POST",
      url: "/partner/continue-setup",
      data: { code },
    });
  }
}

// TODO: this should be a type from the json-schemas package
export type DeviceData = {
  active: boolean;
  customerId: string;
  deviceId: string;
  enabled: boolean;
  esp32Id: string;
  installedAt: string;
  kernelIds: string[];
  monitoring: {
    wattage: number;
    efficiency: string;
    heatPump: boolean;
    packagedUnit: boolean;
    refrigerant: string;
    tonnage: number;
    voltage: number;
  };
  partnerId: string;
  subscribed: boolean;
  version: string;
};

export const httpApi = new HttpApi();
